<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent
          class="py-8"
          title="EIS Bullet Impact Test"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="EIS Bullet Impact Test Description"
              size="medium"
            />
            <p class="p-light">
              A 12.7mm armor piercing projectile is fired into a pipe containing
              the substance.
            </p>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              This test measures the susceptibility of the confined substance to
              explosion or detonation when impacted with an armor piecing bullet
              of a given energy. If the pipe is completely fragmented, the
              substance is not considered an extremely insensitive substance
              (EIS).
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objectives</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Substance configuration</td>
                    <td>Repeatably test a specific sample condition</td>
                    <td></td>
                    <td>
                      A sample with length of 20cm and diameter to closely fit
                      in a pipe of inside diameter of approximately 4.5cm ± 10%.
                    </td>
                  </tr>
                  <tr>
                    <td>Weapon</td>
                    <td>
                      Provide a method to fire an armour-piercing bullet with
                      mass of 46 grams into the caped pipe containing the
                      sample.
                    </td>
                    <td></td>
                    <td>
                      Bullet is a standard 12.7mm armour-piercing bullet with
                      mass of 46 grams fired at a velocity of about 840 ± 40 m/s
                    </td>
                  </tr>
                  <tr>
                    <td>Pipe</td>
                    <td>
                      Provide confinement to the sample and mimic an insensitive
                      munition configuration
                    </td>
                    <td></td>
                    <td>
                      Seamless steel pipe with inside diameter of 4.5cm (±10%)
                      and wall thickness of 4mm (±10%) and a length of 20cm.
                      Pipe is closed with steel or cast iron end caps, at least
                      as strong as the tube, torqued to 204 Nm.
                    </td>
                  </tr>
                  <tr>
                    <td>Pipe orientation</td>
                    <td>
                      Pipe is oriented differently through-out the testing to
                      evaluate explosion or detonation susceptibility
                    </td>
                    <td></td>
                    <td>
                      Six tests are completed with six different pipes with the
                      pipe oriented perpendicular (3 trials) and parallel (3
                      trials) to the firing axis so that the bullet impacts the
                      side of the pipe for three trials and the end of the pipe
                      for three trials.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Pipe fragmentation</td>
                    <td>Visual</td>
                    <td>
                      If the pipe is completely fragmented indicating an
                      explosion or detonation, the substance is not an EIS.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full p-4">
            <TitleComponent class="pt-8" title="Setup Example" size="medium" />
            <p class="p-light">
              See the UN Manual of Tests and Criteria (6th ed.) or the TB 700-2
              document for further details.
            </p>
          </div>
        </div>
      </div>
      <SideBar class="w-full sm:w-1/5" />
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
