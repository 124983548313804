<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent
          class="py-8"
          title="EIS External Fire Test"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="EIS External Fire Test Details"
              size="medium"
            />
            <p class="p-light">
              An impingement gun is used to fire a 9 gram piece of consolidated
              substance traveling 150 m/s into a manometric bomb. The pressure
              rate of rise is recorded.
            </p>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The EIS external fire test is completed to determine if the
              candidate for an extremely insensitive substance (EIS) explodes or
              reacts violently with fragments thrown more than 15 m. Detonation,
              explosion, or fragments thrown more than 15 m indicate the
              substance is not a candidate as an EIS.
            </p>
            <TitleComponent class="py-8" title="Equivalence" size="medium" />
            <p class="p-light">
              Similar in test principle to
              <router-link class="link" to="/external-fire-test"
                >UN Series 6 (c)</router-link
              >, with the exception that the substance is confined in 15 pipes
              with caps and then banded together in groups of five.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objective</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Sample configuration</td>
                    <td>Simulate an insensitive munition</td>
                    <td></td>
                    <td>
                      Each test sample is confined in a seamless steel pipe of
                      20 cm in length and internal diameter of 4.5cm (±10%) and
                      wall thickness of 4mm (±10%). The sample should closely
                      fit with similar dimensions. The pipe also has steel or
                      cast iron end caps at least as strong as the pipe, torqued
                      to 204 Nm.
                    </td>
                  </tr>
                  <tr>
                    <td>Configuration of articles</td>
                    <td>
                      Simulate a grouping of insensitive munitions exposed to
                      fire
                    </td>
                    <td></td>
                    <td>
                      A grouping of 5 pipes banded together with two on-top of a
                      set of three. Three adjacent groupings can be tested on
                      the same fire or three fires completed each with a
                      grouping of 5 pipes.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Fuel type (wood, liquid, or gas), configuration, and
                      quantity
                    </td>
                    <td>
                      Create a fire of sufficient intensity and duration that
                      engulfs the product to enable characterization of any
                      dangerous effects
                    </td>
                    <td></td>
                    <td>
                      Fire duration should be at least 30 minutes in duration
                      with sufficient time to consume all of the energetic
                      substances or another fire should be started.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment*</th>
                  </tr>
                  <tr>
                    <td>Mass explosion</td>
                    <td>
                      Observation of consumption rate, video camera evidence,
                      and visual post test inspection. Optional use of blast
                      gauges and high-speed video.
                    </td>
                    <td>
                      Comparing observations against the definition of a mass
                      explosion hazard (substantial proportion of the whole
                      [Subjective] explodes) [Mass/ high explosion hazard: Not
                      an EIS]
                    </td>
                  </tr>
                  <tr>
                    <td>Potentially hazardous projections</td>
                    <td>
                      Damage to the witness screens; field sampling/ cataloging
                    </td>
                    <td>
                      Potentially hazardous projections thrown more than 15
                      m[Fragmentation hazard: Not an EIS]
                    </td>
                  </tr>
                  <tr>
                    <td>No mass reaction hazard</td>
                    <td>Observation of test; video camera evidence</td>
                    <td>[No mass reaction hazard: Candidate as an EIS]</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <SideBar class="w-full sm:w-1/5" />
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
